import { ensure } from "./utils";

export enum AppEngineService {
  PLAYERS = "players",
  SCOREKEEPERS = "scorekeepers",
  ADMIN = "admin"
}
export const SERVICE_NAME: AppEngineService = ensure(
  // @ts-ignore
  process.env.REACT_APP_SERVICE_NAME
) as AppEngineService;
