import _ from "lodash";
import {
  GameEvent,
  Penalty,
  Goal,
  Player,
  Division,
  Game,
  Attendance,
  User,
  PostRequest
} from "./interfaces";
import { DateTime } from "luxon";
import { ColumnType } from "antd/es/table";
import { isMobile } from "is-mobile";

export const allDivisions = [
  Division.UNKNOWN,
  Division.GREEN,
  Division.RED,
  Division.MAROON,
  Division.BLUE
];

export const allKnownDivisions = [
  Division.GREEN,
  Division.RED,
  Division.MAROON,
  Division.BLUE
];

export function formatGameEventTime(event: GameEvent) {
  return event.minute + ":" + _.toString(event.second).padStart(2, "0");
}

function periodLabel(period: number) {
  return period > 3 ? "OT" : period;
}

export function formatISODate(
  iso: string,
  format: string = "ccc, MMM dd h:mma"
) {
  return parseToDateTime(iso).toFormat(format);
}

export function parseToDateTime(iso: string) : DateTime {
  return DateTime.fromISO(iso, { zone: "America/Los_Angeles" });
}

export function isBeforeToday(dateTime: DateTime) {
  return dateTime.startOf("day").toMillis() < DateTime.now().startOf("day").toMillis();
}

export function ensure<T>(x: T | undefined | null): T {
  if (x === undefined || x === null) {
    throw new Error("Value must not be undefined or null.");
  } else {
    return x;
  }
}

export function dtNow(): string {
  return DateTime.utc()
    .setZone("America/Los_Angeles")
    .toISO() || '';
}

export function nDaysFromNow(days: number): string {
  return addTimeToNow(days);
}

export function addTimeToNow(days: number = 0, hours: number = 0): string {
  return DateTime.fromISO(dtNow())
    .plus({ days, hours })
    .toISO() || '';
}

export function getGoalsTableData(
  goals?: Goal[]
): { data: Goal[]; columns: ColumnType<Goal>[] } {
  const data = _.orderBy(
    goals || [],
    ["period", "minute", "second"],
    ["asc", "desc", "desc"]
  );
  const columns: ColumnType<Goal>[] = [
    {
      dataIndex: "period",
      title: "Period",
      render: (text: string, row: Goal) => periodLabel(row.period)
    },
    {
      key: "time",
      title: "Time",
      render: (text: string, row: Goal) => formatGameEventTime(row)
    },
    {
      dataIndex: "scoredByPlayer",
      title: "Goal",
      render: (text: string, row: Goal) => getPlayerDisplay(row.scoredByPlayer)
    },
    {
      dataIndex: "assistedByPlayer1",
      title: "Assist 1",
      render: (text: string, row: Goal) =>
        row.assistedByPlayer1 ? getPlayerDisplay(row.assistedByPlayer1) : ""
    },
    {
      dataIndex: "assistedByPlayer2",
      title: "Assist 2",
      render: (text: string, row: Goal) =>
        row.assistedByPlayer2 ? getPlayerDisplay(row.assistedByPlayer2) : ""
    }
  ];

  return { data, columns };
}

export function getPenaltiesTableData(
  penalties?: Penalty[]
): { data: Penalty[]; columns: ColumnType<Penalty>[] } {
  const columns: ColumnType<Penalty>[] = [
    {
      dataIndex: "period",
      title: "Period",
      render: (text: string, row: Penalty) => periodLabel(row.period)
    },
    {
      key: "time",
      title: "Time",
      render: (text: string, row: Penalty) => formatGameEventTime(row)
    },
    {
      dataIndex: "player",
      title: "Player",
      render: (text: string, row: Penalty) =>
        row.player ? getPlayerDisplay(row.player) : ""
    },
    { dataIndex: "penaltyName", title: "Penalty" }
  ];
  const data = _.orderBy(
    penalties || [],
    ["period", "minute", "second"],
    ["asc", "desc", "desc"]
  );

  return { data, columns };
}

export function sortPlayersByDivisionAndJersey<T extends Player>(
  players: T[]
): T[] {
  return _.orderBy(players, ["division", "jerseyNumber"], ["desc", "asc"]);
}


export function getPlayerDisplay<T extends Player>(p: T): string {
    var base: string;
    base = `(${getDivisionName(p.division)[0]}${p.jerseyNumber}) ${p.firstName} ${
                        p.lastName}`;
    if (p.pronouns && p.pronouns.trim() !== '') {
        return base + ` (${p.pronouns})`
    }
    return base;
}

export function getDivisionName(division: Division) {
  return _.capitalize(Division[division]);
}

export function updateAvailability(
  postRequest: PostRequest,
  user: User,
  row: Game,
  modifyType: string
) {
  // Don't allow deselecting an availability - a player is either available or not.
  const newAttendance = modifyType === "add" ? Attendance.IN : Attendance.OUT;
  if (row.playerAttendance === newAttendance) return;

  postRequest.send({
    gameId: row.id,
    personId: user.personId,
    available: modifyType === "add"
  });
}

export function toModifyType(
  modifyType: string,
  attendance: Attendance | undefined
) {
  if (
    (attendance === Attendance.IN && modifyType === "add") ||
    (attendance === Attendance.OUT && modifyType === "remove")
  ) {
    return "";
  }
  return modifyType;
}

export const IS_MOBILE = isMobile();
