import React  from "react";
import { useGet, usePost } from "../../common/hooks";
import { Division, Player, PostRequest, Team } from "../../common/interfaces";
import {
  Button,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Select
} from "antd";
import { allDivisions, getDivisionName } from "../../common/utils";
import Spinner from "../../common/components/Spinner";
import _ from "lodash";
import { ConfirmationPopUp } from "../../common/components/ConfirmationPopUp";
import {useParams} from "react-router";

interface PhoneNumber {
  phoneNumber: string;
}

export function PersonDashboard() {
  const { personId } = useParams();
  const person = useGet<Player>(`/api/getPlayer?personId=${personId}`);
  const phoneNumber = useGet<PhoneNumber>(
    `/api/getPhoneNumber?personId=${personId}`
  );
  const updateInfoReq = usePost({ uri: "/api/setUserInfo", deps: [person] });
  const activatePlayer = usePost({
    uri: "/api/activatePlayer",
    deps: [person]
  });
  const deactivatePlayer = usePost({
    uri: "/api/deactivatePlayer",
    deps: [person]
  });
  const removePlayerFromTeam = usePost({
    uri: "/api/removePlayerFromTeam",
    deps: [person]
  });

  function getActivationButton(person?: Player) {
    if (person && person.active) {
      return (
        <ConfirmationPopUp
          title={"Deactivate Player"}
          text={
            `Are you sure you want to deactivate ${person.firstName} ${
              person.lastName
            }? ` +
            `This will remove ${
              person.firstName
            } from her team and all upcoming games.`
          }
          confirmLabel={"Deactivate"}
          onConfirm={deactivatePlayer}
          params={{ personId: person.id }}
        />
      );
    }

    return (
      <Button
        type={"primary"}
        onClick={() => activatePlayer.send({ personId: person!.id })}
        loading={activatePlayer.isLoading}
      >
        Activate
      </Button>
    );
  }

  function getRemoveFromTeamButton(player?: Player) {
    if (player && player.onTeam) {
      return (
        <ConfirmationPopUp
          title={`Remove Player from ${player.teamName}`}
          text={
            `Are you sure you want to remove ${player.firstName} ${
              player.lastName
            }` +
            ` from ${player.teamName}? This will remove ${
              player.firstName
            } from` +
            ` all upcoming games, including deleting their upcoming RSVPs.`
          }
          confirmLabel={"Remove"}
          onConfirm={removePlayerFromTeam}
          params={{ personId: player.id, teamId: player.teamId }}
        />
      );
    }

    return <></>;
  }

  if (!person.data || !phoneNumber.data) {
    return <Spinner size={"large"} />;
  }

  return (
    <>
      <Descriptions title="User Info" column={3}>
        <Descriptions.Item label="Name">
          {person.data.firstName} {person.data.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{person.data.email}</Descriptions.Item>
        <Descriptions.Item label="Active">
          {person.data.active ? "Yes" : "No"} {getActivationButton(person.data)}
        </Descriptions.Item>
        <Descriptions.Item label="Division">
          {getDivisionName(person.data.division)}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number">
          {phoneNumber.data.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Jersey #">
          {person.data.jerseyNumber}
        </Descriptions.Item>
        <Descriptions.Item label="PersonId">{person.data.id}</Descriptions.Item>
        <Descriptions.Item label="TeamName">
          {person.data.teamName || "N/A"} {getRemoveFromTeamButton(person.data)}
        </Descriptions.Item>
      </Descriptions>
      <PersonForm person={person.data} postReq={updateInfoReq} />
    </>
  );
}

interface PersonFormProps {
  person: Player;
  postReq: PostRequest;
}

interface PersonFormValues {
  jerseyNumber: number;
  firstName: string;
  lastName: string;
  email: string;
  division: number;
  team: number;
}

export function PersonForm(props: PersonFormProps) {
  const { person, postReq } = props;
  const [form] = Form.useForm();
  const divisionField = Form.useWatch('division', form);

  const personId = person.id;

  const teams = useGet<Team[]>("/api/teams");

  const onSubmit = (values: PersonFormValues) => {
    form.validateFields()
        .then((validValues: PersonFormValues) => {
          postReq.send( {...validValues, personId },
              () => message.success("Information Updated!"));
        });
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  };

  const currentTeam =
    (teams.data || [])
      .filter(team => team.name === person.teamName)
      .map(team => team.id)
      .pop() || -1;
  if (!teams.data) {
    return <Spinner size={"large"} />;
  }
  const teamsAvailable = _.sortBy(teams.data || [], ["name", "id"]);

  return (
    <Form form={form} onFinish={onSubmit} layout={"horizontal"}>
      <Form.Item
          name={"firstName"}
          label={"First Name"}
          rules={[{ required: true, message: "First name is required." }]}
          initialValue={person.firstName}
          {...formItemLayout}>
        <Input />
      </Form.Item>
      <Form.Item
          name={"lastName"}
          label={"Last Name"}
          rules={[{ required: true, message: "Last name is required." }]}
          initialValue={person.lastName}
          {...formItemLayout}>
        <Input />
      </Form.Item>
      <Form.Item
          name={"jerseyNumber"}
          label={"Jersey #"}
          rules={[{ required: true, message: "Jersey number is required. If not assigned yet, use -1." }]}
          initialValue={person.jerseyNumber}
          {...formItemLayout}>
        <InputNumber min={-1} max={200} />
      </Form.Item>
      <Form.Item
          name={"email"}
          label={"Email"}
          rules={[{ required: true, message: "Email is required." }]}
          initialValue={person.email}
          {...formItemLayout}>
        <Input />
      </Form.Item>
      <Form.Item
          label={"Pronouns"}
          name={"pronouns"}
          rules={[{ required: false }]}
          initialValue={person.pronouns}
          {...formItemLayout}>
        <Input />
      </Form.Item>
      <Form.Item
          name={"division"}
          label={"Division"}
          initialValue={person.division}
          {...formItemLayout}>
        <Select value={person.division} disabled={!person.active}>
          {allDivisions.map((division: Division) => (
              <Select.Option key={division} value={division}>
                {getDivisionName(division)}
              </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
          name={"team"}
          label={"Team"}
          initialValue={person.teamId}
          {...formItemLayout}>
        <Select
            value={currentTeam}
            disabled={
              !person.active || divisionField === Division.UNKNOWN
            }
        >
          {teamsAvailable
              .filter(team => team.division === divisionField)
              .map((team: Team) => (
                  <Select.Option key={team.name} value={team.id}>
                    {team.name}
                  </Select.Option>
              ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          type={"primary"}
          htmlType={"submit"}
          loading={postReq.isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
