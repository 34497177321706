import React from "react";
import { usePost } from "../../common/hooks";
import { useUserContext } from "../../common/contexts";
import { User } from "../../common/interfaces";
import { Button, Form, Input, message } from "antd";

interface PersonInfo {
  email: string;
  firstName: string;
  lastName: string;
  pronouns: string
}

interface PersonInfoFormProps {
  user: User;
}

export function PersonInfoForm({user}: PersonInfoFormProps) {
  const { setUser } = useUserContext();
  const [form] = Form.useForm();
  const getUserInfo = usePost({ uri: "/api/user" });
  function resetUser() {
    getUserInfo.send({}, (data: User) => setUser(data));
  }

  const postReq = usePost({
    uri: "/api/setUserInfo"
  });

  const onSubmit = (values: PersonInfo) => {
    form.validateFields()
      .then((validValues: PersonInfo) => {
        postReq.send({ ...validValues, personId: user.personId }, () => {
          resetUser();
          message.success("User information updated.");
        });
      });
  };

  return (
    <Form onFinish={onSubmit} layout={"vertical"} style={{ maxWidth: "500px" }} form={form}>
      <Form.Item
          label={"Email"}
          name={"email"}
          rules={[{ required: true, message: "Email is required." }]}
          initialValue={user.email}>
        <Input />
      </Form.Item>
      <Form.Item
          label={"First Name"}
          name={"firstName"}
          rules={[{ required: true, message: "First name is required." }]}
          initialValue={user.firstName}>
        <Input />
      </Form.Item>
      <Form.Item
          label={"Last Name"}
          name={"lastName"}
          rules={[{ required: true, message: "Last name is required." }]}
          initialValue={user.lastName}>
        <Input />
      </Form.Item>
      <Form.Item
          label={"Pronouns"}
          name={"pronouns"}
          rules={[{ required: false }]}
          initialValue={user.pronouns}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          type={"primary"}
          loading={postReq.isLoading}
          htmlType={"submit"}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
