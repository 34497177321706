export interface GameEvent {
  period: number;
  minute: number;
  second: number;
}

export interface Game {
  id: number;
  scorekeeper: string;
  referee1: string;
  referee2: string;
  comment: string;
  team1Id: number;
  team2Id: number;
  team1Goalie: number;
  team2Goalie: number;
  rinkId: number;

  // augmented
  rinkName: string;
  date: string;
  team1Name: string;
  team2Name: string;
  winningTeamId?: number;
  team1GoalieName?: string;
  team2GoalieName?: string;

  // added when games are fetched for a particular person
  playerAttendance?: Attendance;
  playerAttendanceTeamId?: number;
}

export interface Goal extends GameEvent {
  id: number;

  // augmented
  scoredByPlayer: Player;
  assistedByPlayer1?: Player;
  assistedByPlayer2?: Player;
}

export interface EntityWithAttendance {
  id: number;
  attendance: Attendance;
  inviteSent: boolean;
}

export interface Penalty extends GameEvent {
  id: number;

  // augmented
  player?: Player;
  penaltyName: string;
}

export interface Person {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  pronouns?: string;
}

export interface Player extends Person {
  jerseyNumber: number;
  division: Division;
  active: boolean;
  onTeam?: boolean;
  teamId?: number;
  teamName?: string;
  isCaptain?: boolean;
}

export interface RegistrationPlayer extends Player {
  needsEval: boolean;
  address: string;
  city: string;
  zip: string;
  phoneNumber: string;
  dateOfBirth: string;
  playerType: PlayerType;
}

export interface SubstitutePlayer extends Player {
  subbingPrefs?: SubbingPrefs;
  availability?: Attendance;
  adjacentGames: Game[];
  numGamesSubbed: number;
}

export enum Attendance {
  "IN" = "IN",
  "OUT" = "OUT",
  "UNRESPONDED" = "UNRESPONDED"
}

export interface RosterPlayer extends Player, EntityWithAttendance {
  isSub: boolean;
  isCaptain: boolean;
}

export interface RosterCoach extends Person, EntityWithAttendance {}

export enum UserAuth {
  PLAYER = 1,
  SCOREKEEPER = 3,
  ADMIN = 4
}

export interface User {
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  personId: number;
  authTypes: UserAuth[];
  pronouns?: string;

  // Information about the team this user
  // is on, as of the current season.
  teamId?: number;
  isCaptain: boolean;
  teamUsesJvite: boolean;
}

export interface SubbingPrefs {
  personId: number;
  email: string;
  phoneNumber: string;
  positions: number[];
  contactMethods: number[];
}

export interface Team {
  id: number; // The database id of the team
  teamId: number; // The number of the team, e.g. 1 for B1
  name: string;
  division: Division;
  usingJVite: boolean;
  numPlayers: number;
}

export interface DivisionStats {
  season: Season;
  teamStats: TeamStats[];
  goalieStats: GoalieStats[];
  availableSeasons: Season[];
}

export interface Season {
  label: string;
  path: number;
  order: number;
}

export interface TeamStats {
  teamName: string;
  teamNumber: number; // The number of the team, e.g. 1 for B1
  teamId: number;
  gamesPlayed: number;
  wins: number;
  losses: number;
  ties: number;
  overtimeLosses: number;
  shotsOnGoal: number;
  goalsFor: number;
  goalsAgainst: number;
  points: number;
  playerStats: PlayerStats[];
}

export interface PlayerStats {
  playerName: string;
  personId: number;
  jerseyNumber: string;
  isCaptain: boolean;
  gamesPlayed: number;
  points: number;
  goals: number;
  assists: number;
  penaltyMinutes: number;
}

export interface GoalieStats {
  name: string;
  goalieId: number;
  jerseyNumber: string;
  wins: number;
  losses: number;
  ties: number;
  overtimeLosses: number;
  shotsOnGoal: number;
  saves: number;
  shutouts: number;
  gamesPlayed: number;
  goalsAgainstAverage: string;
  savePercentage: string;
}

export enum Division {
  UNKNOWN = 0,
  GREEN = 1,
  RED = 2,
  MAROON = 3,
  BLUE = 4
}

export enum Rink {
  REDWOOD_CITY = 2,
  SAN_MATEO = 5
}

export enum PlayerType {
  SKATER = 0,
  GOALIE = 1,
  SKATER_AND_GOALIE = 2
}

export interface SeasonRegistration {
  personId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  usaHockeyNumber: string;
  dateOfBirth: string;
  currentDivision: string;
  requestedDivision: string;
  playerType: string;
  needsEval: boolean;
  discount: number;
  total: number;
  date: string;
  discounts: string;
}

export interface Discount {
  id: number;
  description: string;
  code?: string;
  isAutoApplied: boolean;
  expirationDate: string;
  eligibleRegistrationTypes: number[];

  // Only one of amtOff/pctOff will be greater than zero.
  amtOff: number;
  pctOff: number;
}

export interface Scrimmage {
  id: number;
  rinkId: number;
  rinkName: string;
  date: string;
}

export interface ScrimmageAttendance extends EntityWithAttendance {
  rinkId: number;
  rinkName: string;
  date: string;
}

export interface ScrimmagePlayer extends Person, EntityWithAttendance {
  division: Division;
  onHomeTeam?: boolean;
  previousGamesCount: number;
}

export interface SeasonInfo {
  id: number;
  name: string;
  registrationStartDay: string;
  registrationEndDay: string;
  startingDay: string;
  isActive: boolean;
  isOpenForRegistration: boolean;
  showRegistrationBanner: boolean;
  order: number;
  skaterPrice: number;
  goaliePrice: number;
  goalieAndSkaterPrice: number;
  allDiscounts: Discount[];
}

export interface PostRequest {
  send: (
    params: any,
    onSuccess?: (data?: any) => void,
    onError?: () => void
  ) => void;
  isLoading: boolean;
}
